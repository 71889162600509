// Copyright (C) 2024 Pepperdata Inc. - All rights reserved.
// @flow
import React from "react";
import Space from "../ui-elements/space";
import Button from "../ui-elements/button";

type Props = {
  onSubmit: (SyntheticEvent<HTMLFormElement>) => void,
}
type State = {}

export default class LoginFormBodySectionOktaDelegatedLogin extends React.Component<Props, State> {

  ssoSubmitButtonRef: { current: null | React$ElementRef<any> };

  constructor() {
    super();

    this.state = {
      displayPasswordForSsoSupportedUi: false,
    };
    this.ssoSubmitButtonRef = React.createRef();
  }
  focusOnButtonWithDelay = () => {
    if (this.ssoSubmitButtonRef.current) {
      // Delay the focus slightly to wait until the
      // UI slide transition has completed first. If
      // we do not wait, we will lose focus during the
      // UI transition.
      this.ssoSubmitButtonRef.current.focusOnButtonWithDelay();
    }
  };
  render() {
    return <form
      className="input-section"
      onSubmit={this.props.onSubmit}
      autoComplete="off"
    >
      <div className="description">
        This account uses Okta delegated login. <br/>Login with Okta.com to use Pepperdata
      </div>
      <Space height="10px"/>
      <Button
        ref={this.ssoSubmitButtonRef}
        text="Sign In with Okta"
        buttonType="submit"
      />
    </form>
  }
}
